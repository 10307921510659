import { Col, Container, Row } from "react-bootstrap";
import movimentese from "../../images/landing/movimentese.png";
import Image from "react-bootstrap/Image";

export function QueCremos() {
    return (
        <Row className="align-items-center text-sub black-section">
          <section className="col-12 h-100" id="quecremos">
            <Container>
              <h2 className="text-center text-uppercase white-card-header">
                O que cremos?
              </h2>
              <p className="text white-text">
                Somos uma igreja de teologia reformada e cremos nos símbolos de fé oficiais da Igreja Presbiteriana do Brasil:
                Confissão de Fé de Westminster, Catecismo Maior de Westminster e Breve Catecismo de Westminster.
                <br />
                <br />
                Para saber mais, faça o <a href="https://forms.gle/YFXu2FLj6f7VNFZWA">Movimente-se</a>
              </p>
              <Row className="text-center">
                <Col className="col-12 mx-auto">
                  <a href="https://forms.gle/YFXu2FLj6f7VNFZWA" target="_blank">
                    <Image src={movimentese} alt="Responsive image" className="img-movimentese"></Image>
                  </a>
                </Col>
              </Row>
            </Container>
          </section>
        </Row>
    )
}