import apiService from './apiService';
import ILoginRequest from "../../entities/api/requests/ILoginRequest";
import { AxiosResponse } from 'axios';
import jwt_decode from "jwt-decode";
import IApiToken from '../../entities/api/ApiToken';
import { trackPromise } from 'react-promise-tracker';

class authenticationService {
    private TOKEN_KEY = "TOKEN";

    async postUserCredentials(request: ILoginRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("/authentication", request));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;

            throw error;
        }
    }

    isAuthenticated = (): boolean => localStorage.getItem(this.TOKEN_KEY) !== null;

    setToken(token: string): void {
        localStorage.setItem(this.TOKEN_KEY, token);
        let decoded = jwt_decode<IApiToken>(token);
        this.setUserName(decoded.unique_name);
    };

    removeToken = () => {
        localStorage.removeItem(this.TOKEN_KEY);
        localStorage.removeItem('userName');
    };

    getToken = (): (string | null) => localStorage.getItem(this.TOKEN_KEY);

    getUserName = (): string => localStorage.getItem('userName') ?? '';

    private setUserName(userName: string): void {
        localStorage.setItem('userName', userName)
    };
}

export default new authenticationService();
