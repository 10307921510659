import SearchFilter from '../../entities/api/requests/ISearchFilter';
import INewIncomeRequest from '../../entities/api/requests/INewIncomeRequest';
import IIncomeTypeResponse from '../../entities/api/response/IIncomeTypeResponse';
import IIncomeResponse from '../../entities/api/response/IIncomeResponse';
import apiService from './apiService';
import { AxiosResponse } from 'axios';
import ApiHelper from '../../util/apiHelper';
import INewTypeRequest from '../../entities/api/requests/INewIncomeTypeRequest';
import { trackPromise } from 'react-promise-tracker';

class incomeService {

    async addType(incomeType: INewTypeRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("incomes/type", incomeType));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            if (error.response && error.response.status === 400)
                return error.response;
            throw error;
        }
    }

    async add(income: INewIncomeRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("incomes", income));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            if (error.response && error.response.status === 400)
                return error.response;
            throw error;
        }
    }

    async getTypes(): Promise<IIncomeTypeResponse[]> {
        try {
            const { data, status } = await trackPromise(apiService.get<IIncomeTypeResponse[]>("incomes/types"));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async get(): Promise<IIncomeResponse[]> {
        try {
            const { data, status } = await trackPromise(apiService.get<IIncomeResponse[]>("incomes"));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async getById(id: number): Promise<IIncomeResponse> {
        try {
            const { data, status } = await trackPromise(apiService.get<IIncomeResponse>(`incomes/${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async getByFilter(filter: SearchFilter): Promise<IIncomeResponse[]> {
        try {
            let queryString = ApiHelper.createFilterQueryString(filter);
            const { data, status } = await trackPromise(apiService.get<IIncomeResponse[]>(`incomes/filter?${queryString}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async deleteType(id: number): Promise<Boolean> {
        try {
            const { data, status } = await trackPromise(apiService.delete<boolean>(`incomes/type?id=${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async delete(id: number): Promise<Boolean> {
        try {
            const { data, status } = await trackPromise(apiService.delete<boolean>(`incomes?id=${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async update(income: IIncomeResponse): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.put<boolean>(`incomes?id=${income.id}`, income));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }
}

export default new incomeService();
