import { trackPromise } from 'react-promise-tracker';
import INewSupplierRequest from '../../entities/api/requests/INewSupplierRequest';
import ISupplierResponse from '../../entities/api/response/ISupplierResponse';
import apiService from './apiService';
import { AxiosResponse } from 'axios';

class supplierService {
    
    async add(supplier: INewSupplierRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("suppliers", supplier));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            if (error.response && error.response.status === 400)
                return error.response;
            throw error;
        }
    }

    async get(): Promise<ISupplierResponse[]> {
        try {
            const { data, status } = await trackPromise(apiService.get<ISupplierResponse[]>("suppliers"));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async delete(id: number): Promise<Boolean> {
        try {
            const { data, status } = await trackPromise(apiService.delete<boolean>(`suppliers?id=${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }
}

export default new supplierService();
