import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.css";
import logo from '../../images/landing/mvntpreto.png';
import presblogo from '../../images/landing/ipblogo.png';

export default function NavbarLanding() {
    return (
        <Navbar
            expand="lg"
            className="bg-body-tertiary col-12 nav-landing fixed-top"
            data-bs-theme="light"
        >
            <Navbar.Brand href="">
                <img
                    src={logo}
                    width="130"
                    height="30"
                    className="d-inline-block align-top nav-img-left"
                    alt="Comunidade Movimento" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-img-right" />
            <Navbar.Collapse className="justify-content-start">
                <Nav className="me-auto nav-img-left">
                    <Nav.Link href="#quemsomos">Quem Somos</Nav.Link>
                    <Nav.Link href="#comovivemos">Como vivemos</Nav.Link>
                    <Nav.Link href="#quecremos">O que cremos</Nav.Link>
                    <Nav.Link href="#staff">Equipe Pastoral</Nav.Link>
                    <Nav.Link href="#contribua">Contribua</Nav.Link>
                    <Nav.Link href="#endereco">Endereço</Nav.Link>
                </Nav>
                <Nav className="justify-content-end">
                    <Navbar.Text className="text-center">
                        <img
                            src={presblogo}
                            height="50"
                            className="align-top nav-logo-ipb"
                            alt="Comunidade Movimento" />
                    </Navbar.Text>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
