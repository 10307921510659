import INewExpenseRequest from '../../entities/api/requests/INewExpenseRequest';
import INewTypeRequest from '../../entities/api/requests/INewIncomeTypeRequest';
import ISearchFilter from '../../entities/api/requests/ISearchFilter';
import ExpenseResponse from '../../entities/api/response/IExpenseResponse';
import IExpenseResponse from '../../entities/api/response/IExpenseResponse';
import IExpenseTypeResponse from '../../entities/api/response/IExpenseTypeResponse';
import ApiHelper from '../../util/apiHelper';
import apiService from './apiService';
import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

class expenseService {

    async addType(expenseType: INewTypeRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("expenses/type", expenseType));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            if (error.response && error.response.status === 400)
                return error.response;
            throw error;
        }
    }

    async add(expense: INewExpenseRequest): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.post("expenses", expense));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            if (error.response && error.response.status === 400)
                return error.response;
            throw error;
        }
    }

    async getTypes(): Promise<IExpenseTypeResponse[]> {
        try {
            const { data, status } = await trackPromise(apiService.get<IExpenseTypeResponse[]>("expenses/types"));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async get(): Promise<IExpenseResponse[]> {
        try {
            const { data, status } = await trackPromise(apiService.get<IExpenseResponse[]>("expenses"));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async getById(id: number): Promise<IExpenseResponse> {
        try {
            const { data, status } = await trackPromise(apiService.get<IExpenseResponse>(`expenses/${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async getByFilter(filter: ISearchFilter): Promise<ExpenseResponse[]> {
        try {
            let queryString = ApiHelper.createFilterQueryString(filter);
            const { data, status } = await trackPromise(apiService.get<ExpenseResponse[]>(`expenses/filter?${queryString}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async deleteType(id: number): Promise<Boolean> {
        try {
            const { data, status } = await trackPromise(apiService.delete<boolean>(`expenses/type?id=${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async delete(id: number): Promise<Boolean> {
        try {
            const { data, status } = await trackPromise(apiService.delete<boolean>(`expenses?id=${id}`));
            return data;
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }

    async update(expense: ExpenseResponse): Promise<AxiosResponse> {
        try {
            return await trackPromise(apiService.put<boolean>(`expenses?id=${expense.id}`, expense));
        }
        catch (error: any) {
            if (error.response && error.response.status === 401)
                return error.response;
            throw error;
        }
    }



}

export default new expenseService();
