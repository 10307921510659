import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import expenseService from '../../services/api/expenseService';
import INewExpenseRequest from '../../entities/api/requests/INewExpenseRequest';
import IExpenseTypeResponse from '../../entities/api/response/IExpenseTypeResponse';
import '../common.css';
import ISupplierResponse from '../../entities/api/response/ISupplierResponse';
import supplierService from '../../services/api/supplierService';
import CurrencyInput from 'react-currency-input-field';
import NumberHelper from '../../util/numberHelper';

export function NewExpense() {

    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [newExpense, setNewExpense] = useState<INewExpenseRequest>(initialData());
    const [types, setTypes] = useState<IExpenseTypeResponse[]>([]);
    const [suppliers, setSuppliers] = useState<ISupplierResponse[]>([]);

    function initialData(): INewExpenseRequest | (() => INewExpenseRequest) {
        return { amount: 0, description: '', expenseTypeId: 0, isPaid: false, paymentDate: new Date(), supplierId: 0 };
    }

    const getDate = async () => {
        const types = await expenseService.getTypes();
        setTypes(types);
        const suppliers = await supplierService.get();
        setSuppliers(suppliers);
    }

    useEffect(() => {
        getDate();
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await expenseService.add(newExpense);
            if (response.status === 200) {
                setMessage('Cadastro realizado com sucesso');
                setError('');
                setNewExpense(initialData());
            }
            else if (response.status === 400) {
                setError(response.data[0]);
            }
        }
        catch (error) {
            throw setError('Erro inesperado');
        }
    }

    const cleanMessages = (): void => {
        setMessage('');
        setError('');
    }

    const onChangeType = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, expenseTypeId: parseInt(e.target.value) });
    };

    const onChangeSupplier = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, supplierId: parseInt(e.target.value) });
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, description: e.target.value });
    };

    const onChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, amount: NumberHelper.brazilianToNumber(e.target.value) });
    };

    const onChangeIsPaid = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, isPaid: e.target.checked });
    };

    const onChangePaymentDate = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewExpense({ ...newExpense, paymentDate: new Date(e.target.value) });
    };

    return (
        <Col className="col-10 vh-100">
            <Row className="align-items-center h-100 text-center">
                <Col className='mx-auto' lg='6' sm='8' xs='8'>
                    <Form className='default-form' onSubmit={handleSubmit}>
                        <Col lg='12' sm='12' xs='12'>
                            <Image src={logo} alt="Responsive image" className="img-login"></Image>
                        </Col>
                        <Form.Label className='newIncomeLabel'>Nova despesa</Form.Label>
                        <Col className='col-7 mx-auto' lg='6' sm='12' xs='12'>
                            {message && <Form.Label className='alert-success'>{message}</Form.Label>}
                            {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                            <Form.Group>
                                <Form.Label>Categoria</Form.Label>
                                <Form.Control as="select" onChange={onChangeType} required>
                                    <option value="">Escolha um tipo</option>
                                    {types.map((type, index) => {
                                        return <option key={type.id} value={type.id}>{type.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Fornecedor</Form.Label>
                                <Form.Control as="select" onChange={onChangeSupplier} required>
                                    <option value="">Escolha um fornecedor</option>
                                    {suppliers.map((supplier, index) => {
                                        return <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Aluguel prédio" className='default-field' value={newExpense.description} onChange={onChangeDescription} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Valor</Form.Label>
                                <CurrencyInput className='default-field form-control' placeholder="10,56" decimalsLimit={2} decimalSeparator="," groupSeparator="." onChange={onChangeAmount} />;
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Data pagamento</Form.Label>
                                <Form.Control type="date" className='default-field' onChange={onChangePaymentDate} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label><Form.Check inline type="checkbox" onChange={onChangeIsPaid}></Form.Check> Está Pago?</Form.Label>
                            </Form.Group>
                            <Button className='button-default' variant="light" type="submit">Salvar</Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </Col>
    )
}