import { ChangeEvent, useEffect, useState } from 'react';
import '../common.css';
import incomeService from '../../services/api/incomeService';
import { useParams } from 'react-router';
import IIncomeResponse from '../../entities/api/response/IIncomeResponse';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import IIncomeTypeResponse from '../../entities/api/response/IIncomeTypeResponse';
import DateHelper from '../../util/dateHelper';

export function UpdateIncome(): JSX.Element {
    const { id } = useParams<Record<string, string | undefined>>()
    const [incomeTypes, setIncomeTypes] = useState<IIncomeTypeResponse[]>([]);
    const [income, setIncome] = useState<IIncomeResponse>(GetIncomeInitialData());
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    function GetIncomeInitialData(): IIncomeResponse | (() => IIncomeResponse) {
        return { amount: 0, createdAt: new Date(), description: '', id: 0, incomeType: undefined, incomeTypeId: 0, UpdatedAt: undefined, date: new Date() };
    }

    const getData = async () => {
        let incomeId = (id === undefined || id === null) ? 0 : parseInt(id);
        const data = await incomeService.getById(incomeId);
        const types = (await incomeService.getTypes()).filter(x => x.name != data?.incomeType?.name);
        setIncomeTypes(types);
        setIncome(data);
    }

    useEffect(() => {
        getData()
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await incomeService.update(income);
            if (response.status === 200) {
                setMessage('Atualização realizada com sucesso');
                setError('');
                getData();
            }
            else if (response.status === 400) {
                setError(response.data[0]);
            }
        }
        catch (error) {
            throw setError('Erro inesperado');
        }
    }

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setIncome({ ...income, description: e.target.value });
    };

    const onChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setIncome({ ...income, amount: parseInt(e.target.value) });
    };

    const onChangeIncomeType = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setIncome({ ...income, incomeTypeId: parseInt(e.target.value) });
    };

    const onChangeDate = (e: ChangeEvent<HTMLInputElement>): void => {
        setIncome({ ...income, date: new Date(e.target.value) });
    };

    return (
        <Col className="col-10">
            <Row className="align-items-center h-100">
                <Col className='mx-auto' lg='6' sm='12' xs='12'>
                    <div className='text-center'>
                        <Form className='default-form' onSubmit={handleSubmit}>
                            <Col lg='12' sm='12' xs='12'>
                                <Image src={logo} alt="Responsive image" className="img-login"></Image>
                            </Col>
                            <Form.Label className='newIncomeLabel'>Entrada nº {id}</Form.Label>
                            <Col className='col-7 mx-auto' lg='6' sm='12' xs='12'>
                                {message && <Form.Label className='alert-success'>{message}</Form.Label>}
                                {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                                <Form.Group>
                                    <Form.Label>Categoria</Form.Label>
                                    <Form.Control as="select" onChange={onChangeIncomeType} required>
                                        <option value={id}>{income?.incomeType?.name ?? ""}</option>
                                        {incomeTypes.map((type, index) => {
                                            return <option key={type.id} value={type.id}>{type.name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control type="text" placeholder="Oferta especial" className='default-field' value={income?.description} onChange={onChangeDescription} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Data entrada</Form.Label>
                                    <Form.Control type="date" defaultValue={DateHelper.toDate(income.date)} className='default-field' onChange={onChangeDate} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Valor</Form.Label>
                                    <Form.Control type="number" step={'0.01'} placeholder="10.0" className='default-field' value={income?.amount} onChange={onChangeAmount} required />
                                </Form.Group>
                                <Button className='button-default' variant="light" type="submit">Atualizar</Button>
                            </Col>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}