import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ExpenseChart() {

    const data = {
        labels: ['Estrutura Predial', 'RH', 'Administrativo'],
        datasets: [
            {
                label: 'R$',
                data: [60, 19, 3,],
                backgroundColor: [
                    'rgba(243, 136, 71, 1)',
                    'rgba(125, 125, 125, 1)',
                    'rgba(249, 195, 163, 1)',
                ],
                borderColor: [
                    'rgba(243, 136, 71, 1)',
                    'rgba(125, 125, 125, 1)',
                    'rgba(249, 195, 163, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: "white",
                    font: {
                      size: 12,
                    }
                  }
            },
            title: {
                display: true,
                text: 'Saídas',    
                color: 'white'
            },
        }
    };

    return (
        <Pie data={data} options={options} width={338} height={350} className='card'/>
    )
}