import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import spotify from "../../images/landing/spotify.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faSpotify } from "@fortawesome/free-brands-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export function Endereco() {
    return (
        <Row className="align-items-center text-center endereco">
            <section className="col-12" id="endereco">
                <Container>
                    <Row>
                        <Col className="col-12">
                            <a href="https://open.spotify.com/show/2bibKucCKmD7zlfOw4lnl2?si=50f62eeac41d4184" target="_blank">
                                <Image src={spotify} alt="Responsive image" className="img-spotify"></Image>
                            </a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-2 col-md-auto">
                            <a href="https://www.facebook.com/igrejamovimento" target="_blank" className="a-redes">
                                <FontAwesomeIcon icon={faFacebook as IconProp} />
                            </a>
                        </Col>
                        <Col className="col-2 col-md-auto">
                            <a href="https://www.instagram.com/comunidade_movimento/" target="_blank" className="a-redes">
                                <FontAwesomeIcon icon={faInstagram as IconProp} />
                            </a>
                        </Col>
                        <Col className="col-2 col-md-auto">
                            <a href="https://open.spotify.com/show/2bibKucCKmD7zlfOw4lnl2?si=50f62eeac41d4184" target="_blank" className="a-redes">
                                <FontAwesomeIcon icon={faSpotify as IconProp} />
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <p className="text-endereco ">
                                <strong>Comunidade Movimento</strong> - <a href="https://www.google.com/maps/dir//R.+Dion%C3%ADsio+Rodrigues+da+Silva,+581+-+Campo+Limpo,+Americana+-+SP,+13477-120/@-22.7329652,-47.3877879,12z">Endereço</a>: R. Dionísio Rodrigues da Silva, 581 - Campo Limpo, Americana - SP, 13477-120
                            </p>
                            <p className="text-endereco">
                                <strong>Encontros aos domingos às 10:00</strong>
                            </p>
                            <p className="text-endereco">
                                <strong>COMUNIDADE MOVIMENTO - IGREJA PRESBITERIANA DO BRASIL</strong>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Row>
    )
}
