import { Col, Container, Row } from "react-bootstrap";

export function QuemSomos() {
    return (
        <Row className="align-items-center text-center hero">
            <section className="col-12" id="quemsomos">
                <Container>
                    <Row>
                        <Col className="col-lg-6 col-12 d-flex align-items-center">
                            <h2 className="text-center text-uppercase text-secondary mb-0 text-header">
                                <strong>Quem somos?</strong>
                            </h2>
                        </Col>
                        <Col className="col-lg-6 col-12">
                            <p className="hero-text">
                                Somos uma igreja simples, profunda e excelente.
                                <br />
                                Nos movimentamos para que pessoas se relacionem com
                                <strong> Jesus</strong>.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Row>
    )
}