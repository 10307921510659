export default class Masks {
    
    static document = (value: string) => {
        value = value.replace(/\D/g, "")

        if (value.length <= 11) {
            value = value.replace(/(\d{3})(\d)/, "$1.$2")
            value = value.replace(/(\d{3})(\d)/, "$1.$2")
            value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
            value = value.substring(0, 14);
            value = value.replace(/^(\d{2})(\d)/, "$1.$2")
            value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")
            value = value.replace(/(\d{4})(\d)/, "$1-$2")

        }

        return value
    }

    static zipCode = (value: string) => {
        var regex = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *
        value = value.replace(regex,"$1.$2-$3")
        return value;
    }
}