import Card from 'react-bootstrap/esm/Card';
import './charts.css';


export default function MembersChart() {

    return (
        <Card bg='dark' text='white' style={{ width: '12rem' }}>
        <Card.Header>Membros</Card.Header>
        <Card.Body>
            <p className='p-income-cashier'>60</p>
        </Card.Body>
    </Card>
    )
}