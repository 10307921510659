import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import '../common.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import DefaultModal from '../../components/DefaultModal';
import ISearchFilter from '../../entities/api/requests/ISearchFilter';
import { useHistory } from 'react-router-dom';
import IExpenseResponse from '../../entities/api/response/IExpenseResponse';
import expenseService from '../../services/api/expenseService';
import NumberHelper from '../../util/numberHelper';
import DateHelper from '../../util/dateHelper';
import Spinner from '../../components/Spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export function Expenses() {
    const history = useHistory();
    const [filter, setFilter] = useState<ISearchFilter>({});
    const [expenses, setExpenses] = useState<IExpenseResponse[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState(0);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { promiseInProgress } = usePromiseTracker();

    const handleClose = () => setShowModal(false);

    const getData = async () => {
        const data = await expenseService.get();
        setExpenses(data);
    }

    const confirmDelete = async () => {
        try {
            handleClose();
            let response = await expenseService.delete(idToBeDeleted);
            if (response) {
                setMessage('Registro apagado');
                setError('');
                getData();
            }
            else if (!response) {
                setError('Aconteceu um erro ao tentar apagar o registro');
            }
        } catch (error) {
            throw setError('Erro inesperado');
        }
    }

    useEffect(() => {
        getData()
    }, []);

    const onDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number): void => {
        setMessage("");
        setShowModal(true);
        setIdToBeDeleted(id);
    };

    const onUpdateClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number): void => {
        history.push(`/expenses/${id}`);
    };

    const onSearch = async () => {
        const data = await expenseService.getByFilter(filter);
        setExpenses(data);
    }

    const onChangeInitialDate = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value != '')
            setFilter({ ...filter, initialDate: DateHelper.getOnlyDate(e.target.value) });
    };

    const onChangeEndDate = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value != '')
            setFilter({ ...filter, endDate: DateHelper.getOnlyDate(e.target.value) });
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilter({ ...filter, description: e.target.value });
    };

    return (
        <Col className="col-12">
            <DefaultModal title='Confirma exclusão?' message='Deseja deletar a receita?' show={showModal} close={handleClose} confirm={confirmDelete} />
            <Row className="align-items-center h-100 text-center">
                <Col className='mx-auto col-10' >
                    <Form className='default-form align-items-center vh-100'>
                        <Row className="d-flex">
                            <Col className='col-4'>
                                <div className="form-outline">
                                    <input type="search" id="form1" className="form-control" placeholder={"Digite filtro"} onChange={onChangeDescription} />
                                </div>
                            </Col>
                            <Col className='d-flex col-3'>
                                <label className='label-search'>Início</label>
                                <input type="datetime-local" className="form-control" onChange={onChangeInitialDate} />
                            </Col>
                            <Col className='d-flex col-3'>
                                <label className='label-search'>Fim</label>
                                <input type="datetime-local" className="form-control" onChange={onChangeEndDate} />
                            </Col>
                            <Col className='col-2'>
                                <Button type="button" className="btn btn-primary button-search" onClick={onSearch}>
                                    <i className="fas fa-search ">Buscar</i>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='align-items-center h-100 text-center'>
                            <Col className='text-center mx-auto w-100' lg='4' sm='12' xs='12'>
                                {message && <label className='alert-success'>{message}</label>}
                                {error && <label className='alert-error'>{error}</label>}
                                <Spinner show={promiseInProgress} />
                                <Col lg='12'>
                                    <label>Despesas</label>
                                </Col>
                                <Col lg='12'>
                                    <Table striped bordered hover variant="dark">
                                        <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th>Descrição</th>
                                                <th>Valor</th>
                                                <th>Categoria</th>
                                                <th>Date Vencimento</th>
                                                <th>Está pago?</th>
                                                <th>Fornecedor</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expenses.map((expense, index) => {
                                                return <tr key={index}>
                                                    <td>{expense.id}</td>
                                                    <td>{expense.description}</td>
                                                    <td>{NumberHelper.convertToBrazilianCurrency(expense.amount)}</td>
                                                    <td>{expense.expenseType?.name ?? ""}</td>
                                                    <td>{DateHelper.toBrazilianDate(expense.paymentDate)}</td>
                                                    <td>{expense.isPaid ? "Sim" : "Não"}</td>
                                                    <td>{expense.supplier?.name ?? ""}</td>
                                                    <td className='td-options'>
                                                        <Row className='text-center'>
                                                            <Col sm='6' lg='6' >
                                                                <div className="edit-a" onClick={(e) => onUpdateClick(e, expense.id)}>
                                                                    <FontAwesomeIcon icon={faEdit as IconProp} size="1x" className='home-icon' />
                                                                </div>
                                                            </Col>
                                                            <Col sm='6' lg='6' >
                                                                <div className="edit-a" onClick={(e) => onDeleteClick(e, expense.id)}>
                                                                    <FontAwesomeIcon icon={faTrash as IconProp} size="1x" className='home-icon' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Col>
    )
}