import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import './login.css';
import '../common.css';
import { ChangeEvent, useEffect, useState } from 'react';
import ILoginRequest from '../../entities/api/requests/ILoginRequest';
import authenticationService from '../../services/api/authenticationService';
import { useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from '../../components/Spinner';

export function Login() {
    const history = useHistory();
    const [userRequest, setUserRequest] = useState<ILoginRequest>({ email: '', password: '' });
    const [error, setError] = useState<string>('');
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        authenticationService.removeToken()
    }, []);

    const onUserNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setUserRequest({ ...userRequest, email: e.target.value });
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setUserRequest({ ...userRequest, password: e.target.value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await authenticationService.postUserCredentials(userRequest);
            if (response.status === 200) {
                authenticationService.setToken(`${response.data.token}`);
                history.replace("/home");
            }
            else if (response.status === 401)
                setError("Email e/ou Senha incorretos");
        } catch (error) {
            throw setError("Erro interno, procure o suporte");
        }
    }

    return (
        <Row className="align-items-center h-100 text-center">
            <Form onSubmit={handleSubmit}>
                <Col>
                    <Image src={logo} alt="Responsive image" className="img-fluid img-login"></Image>
                </Col>
                <Spinner show={promiseInProgress} />
                <Col className='col-sm-10 col-md-7 col-lg-7 mx-auto'>
                    {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Digite seu email" className='default-field' onChange={onUserNameChange} required />
                    </Form.Group>
                </Col>
                <Col className='col-sm-10 col-md-7 col-lg-7 mx-auto'>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control type="password" placeholder="*********" className='default-field' onChange={onPasswordChange} required />
                    </Form.Group>
                    <Button className='button-default' variant="light" type="submit">Entrar</Button>
                </Col>
            </Form>
        </Row>
    );
}