import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Home } from "../pages/home/Home";
import { Login } from "../pages/login/Login";
import authenticationService from '../services/api/authenticationService'
import { Members } from '../pages/members/Members';
import SideBar from '../components/Sidebar';
import { NewIncomeType } from '../pages/income/NewIncomeType';
import { IncomeType } from '../pages/income/IncomeType';
import { NewIncome } from '../pages/income/NewIncome';
import { Incomes } from '../pages/income/Incomes';
import { UpdateIncome } from '../pages/income/UpdateIncome';
import { NewSupplier } from '../pages/supplier/NewSupplier';
import { Suppliers } from '../pages/supplier/Suppliers';
import { NewExpense } from '../pages/expense/NewExpense';
import { NewExpenseType } from '../pages/expense/NewExpenseType';
import { ExpenseType } from '../pages/expense/ExpenseType';
import { Expenses } from '../pages/expense/Expenses';
import { Landing } from '../pages/landing/Landing';

function PrivateRoute({ children, ...rest }: any) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                authenticationService.isAuthenticated() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const appRoutes = () => {
    return (
        <div id="app" className='app-menu'>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/acesso" component={Login} />
                    <Route exact path="/" component={Landing} />
                    <>
                        <SideBar />
                        <PrivateRoute path="/home">
                            <Home />
                        </PrivateRoute>
                        <PrivateRoute path="/members">
                            <Members />
                        </PrivateRoute>
                        <PrivateRoute path="/income/type/new">
                            <NewIncomeType />
                        </PrivateRoute>
                        <PrivateRoute path="/income/type">
                            <IncomeType />
                        </PrivateRoute>
                        <PrivateRoute path="/income/new">
                            <NewIncome />
                        </PrivateRoute>
                        <PrivateRoute exact path="/incomes">
                            <Incomes />
                        </PrivateRoute>
                        <PrivateRoute path="/incomes/:id">
                            <UpdateIncome />
                        </PrivateRoute>
                        <PrivateRoute path="/suppliers/new">
                            <NewSupplier />
                        </PrivateRoute>
                        <PrivateRoute exact path="/suppliers">
                            <Suppliers />
                        </PrivateRoute>
                        <PrivateRoute path="/expense/type/new">
                            <NewExpenseType />
                        </PrivateRoute>
                        <PrivateRoute path="/expense/type">
                            <ExpenseType />
                        </PrivateRoute>
                        <PrivateRoute exact path="/expense/new">
                            <NewExpense />
                        </PrivateRoute>
                        <PrivateRoute exact path="/expenses">
                            <Expenses />
                        </PrivateRoute>
                        <PrivateRoute exact path="/expenses/:id">
                            <Expenses />
                        </PrivateRoute>
                    </>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default appRoutes;