import { Col, Container, Row } from "react-bootstrap";

export function ComoVivemos() {
    return (
        <Row className="align-items-center text-sub black-card-section">
          <section className="col-12 h-100" id="comovivemos">
            <Container>
              <h2 className="text-uppercase black-card-text-title">
                Como vivemos
              </h2>
              <p className="text black-card-text-title">"Celebramos com simplicidade, nos relacionamos com excelência e formamos discípulos com profundidade."</p>
              <Row className="text-center">
                <Col className="col-lg-3 col-12 card mx-auto">
                  <b className="text  text-center mb-0 ">Encontros</b>
                  <p className="text">Acreditamos em um ambiente de harmonia, acolhimento, sinceridade, adoração, piedade, alegria, reverência, cuidado, simplicidade, excelência e generosidade</p>
                </Col>
                <Col className="col-lg-3 col-12  card mx-auto">
                  <b className="text text-center mb-0 ">Grupo de conexão</b>
                  <p className="text">Acreditamos em relacionamentos sólidos com excelência; Comunhão e tempo de mesa; Lazer e diversão; Intencionalidade no ensino sobre Jesus</p>
                </Col>
                <Col className="col-lg-3 col-12 card mx-auto">
                  <b className="text text-center mb-0 ">Discipulado</b>
                  <p className="text">Acreditamos na intencionalidade da experiência relacional, ensino teológico saudável e profundo; amizade e prestação de contas</p>
                </Col>
              </Row>
            </Container>
          </section>
        </Row>
    )
}