import axios from 'axios';
import authenticationService from './authenticationService';
import config from '../../config.json';
import configDev from '../../config.dev.json';

function GetApiUrl():string {
    return process.env.NODE_ENV !== 'production' ? configDev.API_URL : config.API_URL
}

const instance = axios.create({
    baseURL: GetApiUrl()
});

instance.interceptors.request.use(async config => {
    const token = authenticationService.getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default instance;