import { Col } from "react-bootstrap";
import "../common.css";
import "./landing.css";
import NavbarLanding from "../../components/landing/NavbarLanding";
import { QuemSomos } from "./QuemSomos";
import { Staff } from "./Staff";
import { Video } from "./Video";
import { ComoVivemos } from "./ComoVivemos";
import { QueCremos } from "./QueCremos";
import { Valores } from "./Valores";
import { Contribua } from "./Contribua";
import { Endereco } from "./Endereco";

export function Landing() {
  return (
    <>
      <Col className="col-12">
        <NavbarLanding />
        <QuemSomos />
        <Video />
        <ComoVivemos />
        <QueCremos />
        <Valores />
        <Staff />
        <Contribua />
        <Endereco />
      </Col>
    </>
  );
}
