import { Col, Row } from "react-bootstrap";
import '../common.css';
import FinanceChart from "../../components/charts/FinanceChart";
import ExpenseChart from "../../components/charts/ExpensesChart";
import MembersChart from "../../components/charts/MembersChart";
import CashierChart from "../../components/charts/CashierChart";

export function Home() {
    return (
        <Col className="col-10 mx-auto">
            <Row className="align-items-center h-100 text-center">
                <Col lg='6' sm='12' className="px-md-5" >
                    <FinanceChart />
                </Col>
                <Col lg='3' md='6' sm='4' className="px-md-5 col-6'">
                    <ExpenseChart />
                </Col>
                <Col lg='2' md='6' sm='4' className="px-md-5 col-6">
                    <MembersChart />
                </Col>
                <Col lg='3' md='6' sm='4' className="px-md-5 col-6">
                    <CashierChart />
                </Col>
            </Row>
        </Col>
    );
}