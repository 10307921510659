import ISearchFilter from "../entities/api/requests/ISearchFilter";

export default class ApiHelper {
    
    static createFilterQueryString(filter: ISearchFilter) {
        let queryString = '';

        if (filter.description) {
            queryString += `description=${filter.description}&`;
        }
        if (filter.initialDate) {
            queryString += `begin=${filter.initialDate?.toISOString()}&`;
        }
        if (filter.endDate) {
            queryString += `end=${filter.endDate?.toISOString()}&`;
        }
        return queryString;
    }
}