import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import '../common.css';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import './income.css';
import incomeService from '../../services/api/incomeService';
import INewIncomeRequest from '../../entities/api/requests/INewIncomeRequest';
import IIncomeTypeResponse from '../../entities/api/response/IIncomeTypeResponse';
import CurrencyInput from 'react-currency-input-field';
import NumberHelper from '../../util/numberHelper';

export function NewIncome() {

    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [newIncome, setNewIncome] = useState<INewIncomeRequest>(initialData());
    const [incomeTypes, setIncomeTypes] = useState<IIncomeTypeResponse[]>([]);

    function initialData(): INewIncomeRequest | (() => INewIncomeRequest) {
        return { amount: 0, description: '', incomeTypeId: 0, date: new Date() };
    }

    const getDate = async () => {
        const data = await incomeService.getTypes();
        setIncomeTypes(data);
    }

    const cleanMessages = (): void => {
        setMessage('');
        setError('');
    }

    useEffect(() => {
        getDate();
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await incomeService.add(newIncome);
            if (response.status === 200) {
                setMessage('Cadastro realizado com sucesso');
                setError('');
                setNewIncome(initialData());
            }
            else if (response.status === 400) {
                setError(response.data[0]);
            }
        }
        catch (error) {
            throw setError('Erro inesperado');
        }
    }

    const onChangeIncomeType = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewIncome({ ...newIncome, incomeTypeId: parseInt(e.target.value) });
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewIncome({ ...newIncome, description: e.target.value });
    };

    const onChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewIncome({ ...newIncome, amount: NumberHelper.brazilianToNumber(e.target.value) });
    };

    const onChangeDate = (e: ChangeEvent<HTMLInputElement>): void => {
        cleanMessages();
        setNewIncome({ ...newIncome, date: new Date(e.target.value) });
    };

    return (
        <Col className="col-10 vh-100">
            <Row className="align-items-center h-100 text-center">
                <Col className='mx-auto' lg='6' sm='8' xs='8'>
                    <Form className='default-form' onSubmit={handleSubmit}>
                        <Col lg='12' sm='12' xs='12'>
                            <Image src={logo} alt="Responsive image" className="img-login"></Image>
                        </Col>
                        <Form.Label className='formLabel'>Nova receita</Form.Label>
                        <Col className='col-7 mx-auto' lg='6' sm='12' xs='12'>
                            {message && <Form.Label className='alert-success'>{message}</Form.Label>}
                            {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                            <Form.Group>
                                <Form.Control as="select" onChange={onChangeIncomeType} required>
                                    <option value="">Escolha um tipo</option>
                                    {incomeTypes.map((type, index) => {
                                        return <option key={type.id} value={type.id}>{type.name}</option>
                                    })}
                                </Form.Control>                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" placeholder="Oferta especial" className='default-field' value={newIncome.description} onChange={onChangeDescription} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Data entrada</Form.Label>
                                <Form.Control type="date" className='default-field' onChange={onChangeDate} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Valor</Form.Label>
                                <CurrencyInput className='default-field form-control' placeholder="10,56" decimalsLimit={2} decimalSeparator="," groupSeparator="." 
                                onChange={(value) => onChangeAmount(value)} />;
                            </Form.Group>
                            <Button className='button-default' variant="light" type="submit">Salvar</Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </Col>
    )
}