import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function FinanceChart() {

    const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: "white",
                    font: {
                      size: 12
                    }
                  }
            },
            title: {
                display: true,
                text: 'Entradas/Saídas',
                color: 'white'
            },
        },
    };

    const monthData = {
        labels,
        datasets: [
            {
                label: 'Entrada',
                data: [1500, 1235, 5000, 4542, 7854, 1582, 1235],
                backgroundColor: 'rgba(255, 255, 255, 1)',
            },
            {
                label: 'Saída',
                data: [2000, 500, 4000, 6000, 3500, 1500, 1000],
                backgroundColor: 'rgba(243, 136, 71, 1)', 
            },
        ],
    };

    return (
        <>
            <Bar options={options} data={monthData} className='card'/>
        </>
    )
}