import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import '../common.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DefaultModal from '../../components/DefaultModal';
import ISupplierResponse from '../../entities/api/response/ISupplierResponse';
import supplierService from '../../services/api/supplierService';
import Masks from '../../util/mask';
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from '../../components/Spinner';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export function Suppliers() {
    const [suppliers, setSupplier] = useState<ISupplierResponse[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState(0);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => setShowModal(false);
    const { promiseInProgress } = usePromiseTracker();

    const getData = async () => {
        const data = await supplierService.get();
        setSupplier(data);
    }

    const confirmDelete = async () => {
        try {
            handleClose();
            let response = await supplierService.delete(idToBeDeleted);
            if (response) {
                setMessage('Registro apagado');
                setError('');
                getData();
            }
            else if (!response) {
                setError('Aconteceu um erro ao tentar apagar o registro');
            }
        } catch (error) {
            throw setError('Erro inesperado');
        }
    }

    useEffect(() => {
        getData()
    }, []);

    const onDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number): void => {
        setMessage("");
        setShowModal(true);
        setIdToBeDeleted(id);
    };

    return (
        <Col className="col-10 vh-100">
            <DefaultModal title='Confirma exclusão?' message='Deseja deletar a entrada?' show={showModal} close={handleClose} confirm={confirmDelete} />
            <Row className="align-items-center h-100 text-center">
                <Col className='text-center mx-auto col-10'>
                    {message && <label className='alert-success'>{message}</label>}
                    {error && <label className='alert-error'>{error}</label>}
                    <Spinner show={promiseInProgress} />
                    <Col lg='12'>
                        <label>Fornecedores</label>
                    </Col>
                    <Col lg='12'>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Documento</th>
                                    <th>Endereço</th>
                                    <th>Cep</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {suppliers.map((supplier, index) => {
                                    return <tr key={index}>
                                        <td>{supplier.id}</td>
                                        <td>{supplier.name}</td>
                                        <td>{supplier.document}</td>
                                        <td>{Masks.document(supplier.address)}</td>
                                        <td>{Masks.zipCode(supplier.zipCode)}</td>
                                        <td className='td-options'>
                                            <Row className='text-center'>
                                                <Col sm='12' lg='12' >
                                                    <div className="edit-a" onClick={(e) => onDeleteClick(e, supplier.id)}>
                                                        <FontAwesomeIcon icon={faTrash as IconProp} size="1x" className='home-icon' />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Col>
            </Row>
        </Col>
    )
}