import { ChangeEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import '../common.css';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import INewSupplierRequest from '../../entities/api/requests/INewSupplierRequest';
import supplierService from '../../services/api/supplierService';
import Masks from '../../util/mask';
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from '../../components/Spinner';

export function NewSupplier() {

    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [newSupplier, setNewSupplier] = useState<INewSupplierRequest>(initialData());

    function initialData(): INewSupplierRequest | (() => INewSupplierRequest) {
        return { address: '', document: '', name: '', zipCode: '' };
    }

    const { promiseInProgress } = usePromiseTracker();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await supplierService.add(newSupplier);
            if (response.status === 200) {
                setMessage('Cadastro realizado com sucesso');
                setError('');
                setNewSupplier(initialData());
            }
            else if (response.status === 400) {
                setError(response.data[0]);
            }
        }
        catch (error) {
            throw setError('Erro inesperado');
        }
    }

    const onChangeDocument = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setNewSupplier({ ...newSupplier, document: Masks.document(e.target.value) });
    };
    const onChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setNewSupplier({ ...newSupplier, name: e.target.value });
    };
    const onChangeAddress = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setNewSupplier({ ...newSupplier, address: e.target.value });
    };
    const onChangeZipcode = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setNewSupplier({ ...newSupplier, zipCode: Masks.zipCode(e.target.value) });
    };




    return (
        <Col className="col-10 vh-100">
            <Row className="align-items-center h-100">
                <Col className='mx-auto' lg='6' sm='8' xs='8'>
                    <div className='text-center'>
                        <Form className='default-form' onSubmit={handleSubmit}>
                            <Col lg='12' sm='12' xs='12'>
                                <Image src={logo} alt="Responsive image" className="img-login"></Image>
                            </Col>
                            <Spinner show={promiseInProgress} />
                            <Form.Label className='newIncomeLabel'>Novo fornecedor</Form.Label>
                            <Col className='col-7 mx-auto' lg='6' sm='12' xs='12'>
                                {message && <Form.Label className='alert-success'>{message}</Form.Label>}
                                {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Padaria" className='default-field' value={newSupplier.name} onChange={onChangeName} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Documento (CPF/CNPJ)</Form.Label>
                                    <Form.Control type="text" placeholder="XX. XXX. XXX/0001-XX" className='default-field' value={newSupplier.document} onChange={onChangeDocument} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control type="text" placeholder="R. almeida nº 15 - PQ América" className='default-field' value={newSupplier.address} onChange={onChangeAddress} />
                                </Form.Group>               <Form.Group>
                                    <Form.Label>CEP</Form.Label>
                                    <Form.Control type="text" placeholder="13.175-644" className='default-field' value={newSupplier.zipCode} onChange={onChangeZipcode} />
                                </Form.Group>
                                <Button className='button-default' variant="light" type="submit">Salvar</Button>
                            </Col>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}