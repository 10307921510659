import { Button, Modal, } from 'react-bootstrap';

export default function DefaultModal({ title, message, show, close, confirm }: any): JSX.Element {

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={confirm}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}