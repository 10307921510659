import { Col, Row } from "react-bootstrap";
import video from "../../images/landing/mvnt.mp4";

export function Video() {
    return (
        <Row className="align-items-center">
            <section className="col-12 video">
                <Col className="col-12">
                    <video width="95%" height="100%" autoPlay={true} loop muted playsInline={true}>
                        <source src={video} type="video/mp4" />
                    </video>
                </Col>
            </section>
        </Row>
    )
}