import { Card, ListGroup } from 'react-bootstrap';
import './charts.css';


export default function CashierChart() {

    return (
        <Card bg='dark' text='white' >
            <Card.Header>Balanço do mês</Card.Header>
            <Card.Body>
                <p className='p-income-cashier'>Entradas R$ 10.0000</p>
                <p className='p-expense-cashier'>Saídas R$ 6000</p>
            </Card.Body>
            <Card.Footer className='p-total-cashier'>Saldo: R$ 4000</Card.Footer>
        </Card>
    )
}