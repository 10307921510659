import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import '../common.css';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logos/logo_principal.png';
import './income.css';
import incomeService from '../../services/api/incomeService';
import INewTypeRequest from '../../entities/api/requests/INewIncomeTypeRequest';
import Spinner from '../../components/Spinner';
import { usePromiseTracker } from 'react-promise-tracker';

export function NewIncomeType() {

    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [newIncomeType, setNewIncomeType] = useState<INewTypeRequest>({ name: '' });

    const { promiseInProgress } = usePromiseTracker();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await incomeService.addType(newIncomeType);
            if (response.status === 200) {
                setMessage('Cadastro realizado com sucesso');
                setError('');
            }
            else if (response.status === 400) {
                setError(response.data[0]);
            }
        }
        catch (error) {
            throw setError('Erro inesperado');
        }
    }

    const onChangeIncomeType = (e: ChangeEvent<HTMLInputElement>): void => {
        setMessage('');
        setError('');
        setNewIncomeType({ ...newIncomeType, name: e.target.value });
    };

    return (
        <Col className="col-10 vh-100">
            <Row className="align-items-center h-100">
                <Col className='mx-auto' lg='6' sm='8' xs='8'>
                    <div className='text-center'>
                        <Form className='default-form' onSubmit={handleSubmit}>
                            <Col lg='12' sm='12' xs='12'>
                                <Image src={logo} alt="Responsive image" className="img-login"></Image>
                            </Col>
                            <Col className='col-7 mx-auto' lg='6' sm='12' xs='12'>
                                {message && <Form.Label className='alert-success'>{message}</Form.Label>}
                                {error && <Form.Label className='alert-error'>{error}</Form.Label>}
                                <Spinner show={promiseInProgress} />
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Digite tipo</Form.Label>
                                    <Form.Control type="text" placeholder="Oferta" className='default-field' value={newIncomeType.name} onChange={onChangeIncomeType} required />
                                </Form.Group>
                                <Button className='button-default' variant="light" type="submit">Salvar</Button>
                            </Col>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}