export default class DateHelper {
    
    static toDate = (date: Date) => {
        return new Date(date).toLocaleDateString('en-CA');
    }

    static toBrazilianDate = (date: Date) => {
        return new Date(date).toLocaleDateString('pt-BR');
    }

    static getOnlyDate = (date: string) => {
        return new Date(date.split('T')[0]);
    }
}