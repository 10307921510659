import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import brunaoFoto from "../../images/landing/staff/brunao.png";
import Carousel from 'react-bootstrap/Carousel';
import thiagaoFoto from "../../images/landing/staff/thiagao.png";
import mazaoFoto from "../../images/landing/staff/mazao.png";
import sauloFoto from "../../images/landing/staff/saulo.png";

export function Staff() {
    return (
        <Row className="align-items-center text-center">
            <section className="col-12" id="staff">
                <h4 className="text-uppercase white-card-header">Equipe Pastoral</h4>
                <Container>
                    <Col className="col-12 d-flex">
                        <Carousel interval={10000} className="carousel-card">
                            <Carousel.Item>
                                <Col className="col-lg-6 col-12">
                                    <Image src={brunaoFoto} alt="Responsive image" className="img-logo"></Image>
                                </Col>
                                <Col className="col-lg-6 col-12 align-self-center">
                                    <h2 className="page-section-heading text-center text-uppercase mb-0 white-card-header">
                                        Pastor Brunão
                                    </h2>
                                    <br />
                                    <p className="text">
                                        <strong>Bruno José Leonardi (37) - Plantador / Revitalizador</strong>
                                    </p>
                                    <p className="text">
                                        Responsável pela <strong>visão ministerial</strong>, <strong>formação de líderes</strong>,
                                        <strong> mentoria e discipulado</strong>
                                    </p>
                                    <p className="text">
                                        É casado com a Lais Leonardi (30), Pai do Theo.
                                    </p>
                                    <p className="text">
                                        É formado em engenharia de controle e automação e bacharel em teologia pelo seminário presbiteriano do sul.
                                    </p>
                                    <p className="text">
                                        Seu chamado é liderar uma igreja que se relaciona com Jesus em tudo que faz, que adora em espírito e em verdade, que ama o próximo, que faz a diferença no mundo e no local onde está.
                                    </p>
                                    <p className="text">
                                        Acredita verdadeiramente em uma igreja <strong>"Simples, profunda e excelente"</strong>
                                    </p>
                                </Col>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Col className="col-lg-6 col-12">
                                    <Image src={sauloFoto} alt="Responsive image" className="img-logo"></Image>
                                </Col>
                                <Col className="col-lg-6 col-12 align-self-center">
                                    <h2 className="page-section-heading text-center text-uppercase mb-0 white-card-header">
                                        Pastor Saulo
                                    </h2>
                                    <br />
                                    <p className="text">
                                        <strong>Saulo Brasileiro (40) - Pastor</strong>
                                    </p>
                                    <p className="text">
                                        Responsável pela <strong> visão ministerial e conselho administrativo da igreja </strong> 
                                    </p>
                                    <p className="text">
                                        É natural de Passos-MG, casado com Thais (38), pai da Milena e do Léo.
                                        Pastor desde 2007 formado pelo Seminário Presbiteriano do Sul.
                                    </p>
                                    <p className="text">
                                        Também estudou <strong>Psicologia</strong> pela faculdade de Americana e psicanalise pelo Instituto Brasileiro de <strong>Psicanálise Clinica.</strong> 
                                    </p>
                                    <p className="text">
                                        Seu chamado é na área de aconselhamento pastoral.
                                    </p>
                                </Col>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Col className="col-lg-6 col-12">
                                    <Image src={thiagaoFoto} alt="Responsive image" className="img-logo"></Image>
                                </Col>
                                <Col className="col-lg-6 col-12 align-self-center">
                                    <h2 className="page-section-heading text-center text-uppercase mb-0 white-card-header">
                                        CoXa
                                    </h2>
                                    <br />
                                    <p className="text">
                                        <strong>Thiago Bortolazzo (41), mais conhecido como CoXa.</strong>
                                    </p>
                                    <p className="text">
                                        Cofundador Da comunidade Movimento.
                                        <strong> Presbítero</strong> e responsável pela Mvnt MUSIC e Mvnt HOMEM, formação de líderes, mentoria e discipulado.
                                    </p>
                                    <p className="text">
                                        É natural de Piracicaba-SP, casado com a Tábita  (37), Pai da Antonella e Daniel. É bacharel em Logística, cursou Teologia e Liderança no  Seminário Bíblico Palavra da Vida (SBPV).
                                    </p>
                                    <p className="text">
                                        <strong> Seu chamado é servir e conectar </strong> as pessoas a Jesus através da música, resgatar a parte diaconal da música dentro e fora da igreja, pregar evangelho de forma simples, profunda e excelente e formar discípulos que experimentem no ordinário do dia a dia o relacionamento com Jesus e o seu amor através do evangelho.
                                    </p>
                                </Col>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Col className="col-lg-6 col-12">
                                    <Image src={mazaoFoto} alt="Responsive image" className="img-logo"></Image>
                                </Col>
                                <Col className="col-lg-6 col-12 align-self-center">
                                    <h2 className="page-section-heading text-center text-uppercase mb-0 white-card-header">
                                        Mazão
                                    </h2>
                                    <br />
                                    <p className="text">
                                        <strong>Marcelo de Souza (43), mais conhecido como Mazão.</strong>
                                    </p>
                                    <p className="text">
                                        Cofundador Da comunidade Movimento.
                                        <strong> Presbítero</strong> pelo 2º mandato, vice presidente do conselho, responsável pela integração, Líder de Grupo de Conexão e um dos Bateristas da Comunidade.
                                    </p>
                                    <p className="text">
                                        É casado com Talita M. S. Souza (41), Pai da Isadora de Souza e Lorena de Souza.
                                    </p>
                                    <p className="text">
                                        <strong> Seu chamado é liderar </strong> juntamente com a equipe pastoral, dando todo o suporte necessário aos pastores e membros.                                    </p>
                                    <p className="text">
                                        Servindo pessoas para que elas se conectem a Jesus de uma forma <strong>Simples, Profunda e Excelente</strong>
                                    </p>
                                </Col>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Container>
            </section>
        </Row>
    )
}