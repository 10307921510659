import { Col, Container, Row } from "react-bootstrap";

export function Valores() {
    return (
        <Row className="align-items-center text-sub black-card-section">
          <section className="col-12" id="">
            <Container>
              <Col className="col-12">
                <h2 className="text-center text-uppercase black-card-text-title">
                  Nossos Valores
                </h2>
              </Col>
              <Row className="text-center">
                <Col className="col-lg-3 col-12 card mx-auto ">
                  <b className="text text-center mb-0 ">Simplicidade</b>
                  <p className="text">Jesus é simples. Acreditamos que o simples funciona, conecta, traz leveza. As pessoas estão cansadas da agitação sem propósito, da complexidade do mundo moderno. Ser simples pra nós é viver como Jesus.</p>
                </Col>
                <Col className="col-lg-3 col-12  card mx-auto">
                  <b className="text text-center mb-0">Profundidade</b>
                  <p className="text">O Espírito Santo é profundo. Acreditamos na riqueza da experiência íntima com Cristo, por meio da cultura do discipulado eficaz e uma liturgia cristocêntrica.</p>
                </Col>
                <Col className="col-lg-3 col-12  card mx-auto">
                  <b className="text text-center mb-0 ">Excelência</b>
                  <p className="text">O Pai é excelente. Ele criou todas as coisas com excelência. Somos obra prima de suas mãos. Portanto, tudo que fazemos é muito bem feito para glória do pai e para inspirar pessoas a glorificá-lo.</p>
                </Col>
              </Row>
            </Container>
          </section>
        </Row>
    )
}