import { Col, Container, Row } from "react-bootstrap";
import contribua from "../../images/landing/contribua.png";
import Image from "react-bootstrap/Image";

export function Contribua() {
    return (
        <Row className="align-items-center text-center black-card-section " >
          <section className="col-12" id="contribua">
            <Col className="col-12">
              <Image src={contribua} alt="Responsive image" className="img-movimentese"></Image>
            </Col>
          </section>
        </Row>
    )
}