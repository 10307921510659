import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import '../common.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DefaultModal from '../../components/DefaultModal';
import IExpenseTypeResponse from '../../entities/api/response/IExpenseTypeResponse';
import expenseService from '../../services/api/expenseService';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export function ExpenseType() {

    const [types, setTypes] = useState<IExpenseTypeResponse[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState(0);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => setShowModal(false);

    const getData = async () => {
        const data = await expenseService.getTypes();
        setTypes(data);
    }

    const confirmDelete = async () => {
        try {
            handleClose();
            let response = await expenseService.deleteType(idToBeDeleted);
            if (response) {
                setMessage('Registro apagado');
                setError('');
                getData();
            }
            else if (!response) {
                setError('Aconteceu um erro ao tentar apagar o registro');
            }
        } catch (error) {
            throw setError('Erro inesperado');
        }
    }

    useEffect(() => {
        getData()
    }, []);

    const onDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number): void => {
        setMessage("");
        setShowModal(true);
        setIdToBeDeleted(id);
    };

    return (
        <Col className="col-10 vh-100">
            <DefaultModal title='Confirma exclusão?'
                message='Para deletar um tipo de receita a mesma não pode estar sendo utilizada!'
                show={showModal}
                close={handleClose}
                confirm={confirmDelete} />
            <Row className="align-items-center h-100 text-center">
                <Col className='text-center mx-auto col-10'>
                    {message && <label className='alert-success'>{message}</label>}
                    {error && <label className='alert-error'>{error}</label>}
                    <Col lg='12'>
                        <label>Tipos despesas</label>
                    </Col>
                    <Col lg='12'>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {types.map((type, index) => {
                                    return <tr key={index}>
                                        <td>{type.id}</td>
                                        <td>{type.name}</td>
                                        <td>
                                            <Col sm='12' lg='12' >
                                                <div className="edit-a" onClick={(e) => onDeleteClick(e, type.id)}>
                                                    <FontAwesomeIcon icon={faTrash as IconProp} size="1x" className='home-icon' />
                                                </div>
                                            </Col>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Col>
            </Row>
        </Col>
    )
}