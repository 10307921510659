import './sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faBars, faBuilding, faReceipt, faMoneyCheckDollar, faRightFromBracket, faHouse } from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export default function SideBar() {
    const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

    const onSideBarCollapse = (): void => {
        setSideBarCollapsed(!sideBarCollapsed);
    };

    return (
        <Sidebar className='sidebar-menu' backgroundColor='none' collapsed={sideBarCollapsed}>
            <Menu>
                <MenuItem className="sidebar-header" icon={<FontAwesomeIcon size="lg" icon={faBars as IconProp} />} onClick={onSideBarCollapse} >
                    <h2> MVNT</h2>
                </MenuItem>
                <MenuItem icon={<FontAwesomeIcon size="lg" icon={faHouse as IconProp} />} component={<Link to="/home" />}> Home </MenuItem>
                <SubMenu label="Membros" icon={<FontAwesomeIcon size="lg" icon={faPerson as IconProp} />}>
                    <MenuItem className='menu-item'> Cadastrar </MenuItem>
                    <MenuItem className='menu-item' component={<Link to="/members" />}> Consultar </MenuItem>
                </SubMenu>
                <SubMenu label="Receitas" icon={<FontAwesomeIcon size="lg" icon={faMoneyCheckDollar as IconProp} />}>
                    <SubMenu label="Categorias" className='menu-item'>
                        <MenuItem className='menu-item' component={<Link to="/income/type/new" />}> Cadastrar </MenuItem>
                        <MenuItem className='menu-item' component={<Link to="/income/type" />}> Consultar </MenuItem>
                    </SubMenu>
                    <MenuItem className='menu-item' component={<Link to="/income/new" />}> Cadastrar</MenuItem>
                    <MenuItem className='menu-item' component={<Link to="/incomes" />}> Consultar </MenuItem>
                </SubMenu>
                <SubMenu label="Despesas" icon={<FontAwesomeIcon size="lg" icon={faReceipt as IconProp} />}>
                    <MenuItem className='menu-item' component={<Link to="/expense/new" />}> Cadastrar </MenuItem>
                    <MenuItem className='menu-item' component={<Link to="/expenses" />}> Consultar </MenuItem>
                    <SubMenu label="Tipos" className='menu-item'>
                        <MenuItem className='menu-item' component={<Link to="/expense/type/new" />}> Cadastrar </MenuItem>
                        <MenuItem className='menu-item' component={<Link to="/expense/type" />}> Consultar </MenuItem>
                    </SubMenu>
                </SubMenu>
                <SubMenu label="Fornecedores" icon={<FontAwesomeIcon size="lg" icon={faBuilding as IconProp} />}>
                    <MenuItem className='menu-item' component={<Link to="/suppliers/new" />}> Cadastrar </MenuItem>
                    <MenuItem className='menu-item' component={<Link to="/suppliers" />}> Consultar</MenuItem>
                </SubMenu>
                <MenuItem icon={<FontAwesomeIcon size="lg" icon={faRightFromBracket as IconProp} />} component={<Link to="/" />}> Logout </MenuItem>
            </Menu>
        </Sidebar>
    )
}